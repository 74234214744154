import { useEffect, useMemo, useState } from 'react';
import { Home } from '../../pages/Home';
import { Registration } from '../../pages/Registration';
import { Start } from '../../pages/Start';
import { Game } from '../../pages/Game';
import { Final } from '../../pages/Final';
import { PageContext } from './PageContext';
import { Loader } from '../Loader';

export const App = () => {
  const [page, setPage] = useState('home'); // home / registration / start / game / final
  const [showLoader, setShowLoader] = useState(true);

  const renderCurrentPage = useMemo(() => {
    switch (page) {
      case 'home':
        return <Home />;
      case 'registration':
        return <Registration />;
      case 'start':
        return <Start />;
      case 'game':
        return <Game />;
      case 'final':
        return <Final />;
      default:
        return <div className="error_render">Ошибка состояния приложения!</div>;
    }
  }, [page]);

  let timerId: ReturnType<typeof setTimeout>;

  useEffect(() => {
    timerId = setTimeout(
      () => {
        setShowLoader(false);
      },
      process.env.NODE_ENV === 'development' ? 10 : 2000,
    );

    return () => clearTimeout(timerId);
  }, []);

  return (
    <PageContext.Provider value={{ page, setPage }}>
      {showLoader ? <Loader /> : renderCurrentPage}
    </PageContext.Provider>
  );
};
