import { motion } from 'framer-motion';
import { ReactNode } from 'react';

type TProps = {
  children: ReactNode;
  delay?: number;
  className?: string;
  marginBottom?: string;
};

export const AnimateFade = ({
  children,
  delay = 0,
  className,
  marginBottom = '-50px',
}: TProps) => {
  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      whileInView={{ opacity: 1, y: 0 }}
      transition={{ delay }}
      className={className}
      viewport={{
        once: true,
        margin: `0px 0px ${marginBottom} 0px`,
      }}
    >
      {children}
    </motion.div>
  );
};
