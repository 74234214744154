import { RootState } from '..';

export const gameTotalSelector = (state: RootState) => state.game.total;

export const gameUpSelector = (state: RootState) => state.game.up;

export const gameSwordSelector = (state: RootState) => state.game.sword;

export const gameShieldSelector = (state: RootState) => state.game.shield;

export const gameMoneySelector = (state: RootState) => state.game.money;

export const gameLifeSelector = (state: RootState) => state.game.life;
