export const TOTAL_SECONDS_EXPIRED = 120;

// Bgs

import bg1Src from './images/game-bg-1.jpg';
export const BG1_IMAGE = new Image();
BG1_IMAGE.src = bg1Src;

import bg2Src from './images/game-bg-2.jpg';
export const BG2_IMAGE = new Image();
BG2_IMAGE.src = bg2Src;

import bg3Src from './images/game-bg-3.jpg';
export const BG3_IMAGE = new Image();
BG3_IMAGE.src = bg3Src;

import bg4Src from './images/game-bg-4.jpg';
export const BG4_IMAGE = new Image();
BG4_IMAGE.src = bg4Src;

import bg5Src from './images/game-bg-5.jpg';
export const BG5_IMAGE = new Image();
BG5_IMAGE.src = bg5Src;

import bg6aSrc from './images/game-bg-6-1.jpg';
export const BG6A_IMAGE = new Image();
BG6A_IMAGE.src = bg6aSrc;

import bg6bSrc from './images/game-bg-6-2.jpg';
export const BG6B_IMAGE = new Image();
BG6B_IMAGE.src = bg6bSrc;

import bg7aSrc from './images/game-bg-7-1.jpg';
export const BG7A_IMAGE = new Image();
BG7A_IMAGE.src = bg7aSrc;

import bg7bSrc from './images/game-bg-7-2.jpg';
export const BG7B_IMAGE = new Image();
BG7B_IMAGE.src = bg7bSrc;

import bg8Src from './images/game-bg-8.jpg';
export const BG8_IMAGE = new Image();
BG8_IMAGE.src = bg8Src;

import bg9Src from './images/game-bg-9.jpg';
export const BG9_IMAGE = new Image();
BG9_IMAGE.src = bg9Src;

import baseSrc from './images/base.svg';
export const BASE_IMAGE = new Image();
BASE_IMAGE.src = baseSrc;

// Player

import playerSrc from './images/char.png';
export const PLAYER_IMAGE = new Image();
PLAYER_IMAGE.src = playerSrc;

import playerLeftSrc from './images/char-left.png';
export const PLAYER_LEFT_IMAGE = new Image();
PLAYER_LEFT_IMAGE.src = playerLeftSrc;

import playerRightSrc from './images/char-right.png';
export const PLAYER_RIGHT_IMAGE = new Image();
PLAYER_RIGHT_IMAGE.src = playerRightSrc;

import playerLeftSwordSrc from './images/char-left-sword.png';
export const PLAYER_LEFT_SWORD_IMAGE = new Image();
PLAYER_LEFT_SWORD_IMAGE.src = playerLeftSwordSrc;

import playerRightSwordSrc from './images/char-right-sword.png';
export const PLAYER_RIGHT_SWORD_IMAGE = new Image();
PLAYER_RIGHT_SWORD_IMAGE.src = playerRightSwordSrc;

import playerLeftShieldSrc from './images/char-left-shield.png';
export const PLAYER_LEFT_SHIELD_IMAGE = new Image();
PLAYER_LEFT_SHIELD_IMAGE.src = playerLeftShieldSrc;

import playerRightShieldSrc from './images/char-right-shield.png';
export const PLAYER_RIGHT_SHIELD_IMAGE = new Image();
PLAYER_RIGHT_SHIELD_IMAGE.src = playerRightShieldSrc;

// Platforms

import platform1Src from './images/platform-1.png';
export const PLATFORM1_IMAGE = new Image();
PLATFORM1_IMAGE.src = platform1Src;

import platformBroken1Src from './images/platform-broken-1.png';
export const PLATFORM1_BROKEN_IMAGE = new Image();
PLATFORM1_BROKEN_IMAGE.src = platformBroken1Src;

import platform2Src from './images/platform-2.png';
export const PLATFORM2_IMAGE = new Image();
PLATFORM2_IMAGE.src = platform2Src;

import platformBroken2Src from './images/platform-broken-2.png';
export const PLATFORM2_BROKEN_IMAGE = new Image();
PLATFORM2_BROKEN_IMAGE.src = platformBroken2Src;

import platform3Src from './images/platform-3.png';
export const PLATFORM3_IMAGE = new Image();
PLATFORM3_IMAGE.src = platform3Src;

import platformBroken3Src from './images/platform-broken-3.png';
export const PLATFORM3_BROKEN_IMAGE = new Image();
PLATFORM3_BROKEN_IMAGE.src = platformBroken3Src;

import platform4Src from './images/platform-4.png';
export const PLATFORM4_IMAGE = new Image();
PLATFORM4_IMAGE.src = platform4Src;

import platformBroken4Src from './images/platform-broken-4.png';
export const PLATFORM4_BROKEN_IMAGE = new Image();
PLATFORM4_BROKEN_IMAGE.src = platformBroken4Src;

import platform5Src from './images/platform-5.png';
export const PLATFORM5_IMAGE = new Image();
PLATFORM5_IMAGE.src = platform5Src;

import platformBroken5Src from './images/platform-broken-5.png';
export const PLATFORM5_BROKEN_IMAGE = new Image();
PLATFORM5_BROKEN_IMAGE.src = platformBroken5Src;

import platform6Src from './images/platform-6.png';
export const PLATFORM6_IMAGE = new Image();
PLATFORM6_IMAGE.src = platform6Src;

import platformBroken6Src from './images/platform-broken-6.png';
export const PLATFORM6_BROKEN_IMAGE = new Image();
PLATFORM6_BROKEN_IMAGE.src = platformBroken6Src;

import platform7Src from './images/platform-7.png';
export const PLATFORM7_IMAGE = new Image();
PLATFORM7_IMAGE.src = platform7Src;

import platformBroken7Src from './images/platform-broken-7.png';
export const PLATFORM7_BROKEN_IMAGE = new Image();
PLATFORM7_BROKEN_IMAGE.src = platformBroken7Src;

import platform8aSrc from './images/platform-8-1.png';
export const PLATFORM8A_IMAGE = new Image();
PLATFORM8A_IMAGE.src = platform8aSrc;

import platform8bSrc from './images/platform-8-2.png';
export const PLATFORM8B_IMAGE = new Image();
PLATFORM8B_IMAGE.src = platform8bSrc;

import platform8cSrc from './images/platform-8-3.png';
export const PLATFORM8C_IMAGE = new Image();
PLATFORM8C_IMAGE.src = platform8cSrc;

import platform8dSrc from './images/platform-8-4.png';
export const PLATFORM8D_IMAGE = new Image();
PLATFORM8D_IMAGE.src = platform8dSrc;

import platform8eSrc from './images/platform-8-5.png';
export const PLATFORM8E_IMAGE = new Image();
PLATFORM8E_IMAGE.src = platform8eSrc;

import platformBroken8Src from './images/platform-broken-8.png';
export const PLATFORM8_BROKEN_IMAGE = new Image();
PLATFORM8_BROKEN_IMAGE.src = platformBroken8Src;

// Obstacles

import obstacle1Src from './images/obstacle-1.png';
export const OBSTACLE1_IMAGE = new Image();
OBSTACLE1_IMAGE.src = obstacle1Src;

import obstacle2Src from './images/obstacle-2.png';
export const OBSTACLE2_IMAGE = new Image();
OBSTACLE2_IMAGE.src = obstacle2Src;

import obstacle3Src from './images/obstacle-3.png';
export const OBSTACLE3_IMAGE = new Image();
OBSTACLE3_IMAGE.src = obstacle3Src;

import obstacle4Src from './images/obstacle-4.png';
export const OBSTACLE4_IMAGE = new Image();
OBSTACLE4_IMAGE.src = obstacle4Src;

import obstacle5Src from './images/obstacle-5.png';
export const OBSTACLE5_IMAGE = new Image();
OBSTACLE5_IMAGE.src = obstacle5Src;

import obstacle6Src from './images/obstacle-6.png';
export const OBSTACLE6_IMAGE = new Image();
OBSTACLE6_IMAGE.src = obstacle6Src;

import obstacle7Src from './images/obstacle-7.png';
export const OBSTACLE7_IMAGE = new Image();
OBSTACLE7_IMAGE.src = obstacle7Src;

// Bonuses

import upSrc from './images/up.png';
export const UP_IMAGE = new Image();
UP_IMAGE.src = upSrc;

import swordSrc from './images/sword.png';
export const SWORD_IMAGE = new Image();
SWORD_IMAGE.src = swordSrc;

import shieldSrc from './images/shield.png';
export const SHIELD_IMAGE = new Image();
SHIELD_IMAGE.src = shieldSrc;

import moneySrc from './images/money.png';
export const MONEY_IMAGE = new Image();
MONEY_IMAGE.src = moneySrc;

import lifeSrc from './images/life.png';
export const LIFE_IMAGE = new Image();
LIFE_IMAGE.src = lifeSrc;
