import styles from './Menu.module.scss';
import { TOTAL_SECONDS_EXPIRED } from '@/src/constants';
import cn from 'classnames';

import pause from '../../images/pause.svg';

type TProps = {
  lives?: number;
  time?: number;
  score?: number;
};

export const Menu = ({
  lives = 3,
  time = TOTAL_SECONDS_EXPIRED,
  score = 0,
}: TProps) => {
  return (
    <div className={styles.menu}>
      <div className={styles.left}>
        <button className={styles.pause}>
          <img src={pause} alt="" />
        </button>
        <div className={cn(styles.lives, styles[`lives${lives}`])}></div>
      </div>
      <div className={styles.right}>
        <div className={styles.time}>{time}</div>
        <div className={styles.score}>{score}</div>
      </div>
    </div>
  );
};
