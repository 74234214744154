import { usePageContext } from '@/src/components/App/PageContext';
import styles from './RegistrationFooter.module.scss';
import { noop } from '@/src/utils';
import cn from 'classnames';
import { AnimateFade } from '@/src/components/AnimateFade';

export const RegistrationFooter = () => {
  const pageContext = usePageContext();
  const changePage = pageContext?.setPage || noop;

  const handleGoGame = () => {
    changePage('game');
  };

  return (
    <div className={styles.root}>
      <AnimateFade className={styles.content} delay={0.2}>
        <b>Не нужны призы?</b>
        Играйте без регистрации
      </AnimateFade>
      <AnimateFade className={styles.button_wrapper} delay={0.4}>
        <button
          className={cn('button', styles.button)}
          type="button"
          onClick={handleGoGame}
        >
          Играть
        </button>
      </AnimateFade>
    </div>
  );
};
