import styles from './Header.module.scss';
import cn from 'classnames';

import logo from '../../images/logo.svg';
import hhLogo from '../../images/hh.svg';
import { AnimateFade } from '../AnimateFade';

type TProps = {
  isLarge?: boolean;
};

export const Header = ({ isLarge }: TProps) => {
  return (
    <div
      className={cn(styles.header, {
        [styles['header--large']]: isLarge,
      })}
    >
      <div className="container">
        <div className={styles.wrap}>
          <AnimateFade className={styles.logo}>
            <img src={logo} alt="" />
          </AnimateFade>
          <div className={styles.vote}>
            <AnimateFade className={styles.voteText} delay={0.2}>
              <p>
                Поддержи нас
                <br />
                в&#160;рейтинге работодателей
              </p>
              <img src={hhLogo} alt="" />
            </AnimateFade>

            <AnimateFade delay={0.4}>
              <a
                href=""
                target="_blank"
                rel="noreferrer"
                className={cn('button', styles.voteButton)}
              >
                Голосовать
              </a>
            </AnimateFade>
          </div>
        </div>
      </div>
    </div>
  );
};
