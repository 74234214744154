import { useMediaQuery } from 'usehooks-ts';
import styles from './RegistrationHero.module.scss';

import prize1Src from './images/prize1.png';
import prize2Src from './images/prize2.png';
import { Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { AnimateFade } from '@/src/components/AnimateFade';

const PRIZES = [
  {
    name: '10 рюкзаков',
    text: 'Стильный вместительный рюкзак из прочного материала — идеальный спутник в любом путешествии',
    image: prize1Src,
  },
  {
    name: '5 толстовок',
    text: 'Уютная теплая толстовка в современном дизайне — для прогулок, отдыха дома или активных выходных',
    image: prize2Src,
  },
];

export const RegistrationHero = () => {
  const isSizeS = useMediaQuery('(max-width: 1019px)');

  return (
    <div className={styles.root}>
      <AnimateFade className={styles.title} delay={0.4}>
        Вас ждут приятные призы!
      </AnimateFade>

      <div className={styles.items}>
        {!isSizeS ? (
          PRIZES.map(({ name, text, image }, index) => {
            return (
              <AnimateFade
                className={styles.item}
                delay={0.3 * (index + 1)}
                key={name}
              >
                <div className={styles.image}>
                  <img src={image} alt="" />
                </div>
                <div className={styles.content}>
                  <b>{name}</b>
                  <p>{text}</p>
                </div>
              </AnimateFade>
            );
          })
        ) : (
          <AnimateFade delay={0.6}>
            <Swiper
              spaceBetween={20}
              modules={[Pagination]}
              pagination={{ el: '.prizes-pagination', clickable: true }}
              autoHeight
            >
              {PRIZES.map(({ name, text, image }) => (
                <SwiperSlide key={name}>
                  <div className={styles.item} key={name}>
                    <div className={styles.image}>
                      <img src={image} alt="" />
                    </div>
                    <div className={styles.content}>
                      <b>{name}</b>
                      <p>{text}</p>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
            <div className="prizes-pagination" />
          </AnimateFade>
        )}
      </div>

      <AnimateFade className={styles.disclaimer} delay={0.8}>
        <p>
          Розыгрыш проводится с 01.10.2024 по 31.10.2024 гг. <br /> Победители
          будут определены среди всех зарегистрированных пользователей,
          прошедших игру, случайным образом с помощью рандомайзера.
        </p>
        <p>
          <a href="#">Условия розыгрыша</a>
        </p>
      </AnimateFade>
    </div>
  );
};
