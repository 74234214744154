import * as yup from 'yup';
import { emailRegExp } from './regex';

export const REQUIRED_ERROR_MESSAGE = 'Обязательное поле';

export const validationStringRequired = yup
  .string()
  .required(REQUIRED_ERROR_MESSAGE);

export const validationEmail = yup
  .string()
  .required(REQUIRED_ERROR_MESSAGE)
  .matches(emailRegExp, 'Введите email в формате index@inbox.ru');
