import { useForm } from 'react-hook-form';
import styles from './RegistrationForm.module.scss';
import cn from 'classnames';
import { object } from 'yup';
import {
  validationEmail,
  validationStringRequired,
} from '@/src/utils/validation';
import { yupResolver } from '@hookform/resolvers/yup';
import { usePageContext } from '@/src/components/App/PageContext';
import { noop } from '@/src/utils';
import { useMediaQuery } from 'usehooks-ts';
import { AnimateFade } from '@/src/components/AnimateFade';

type TFields = {
  name: string;
  email: string;
};

export const RegistrationForm = () => {
  const pageContext = usePageContext();
  const changePage = pageContext?.setPage || noop;

  const isSizeXS = useMediaQuery('(max-width: 699px)');

  const schema = () => {
    return object({
      name: validationStringRequired,
      email: validationEmail,
    });
  };

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<TFields>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      name: '',
      email: '',
    },
    resolver: yupResolver(schema()),
    shouldFocusError: true,
  });

  const onSubmit = (data: TFields) => {
    console.log('data', data);

    changePage('game');
  };

  return (
    <AnimateFade className={styles.root} delay={1}>
      <div className={styles.title}>
        Зарегистрируйся, чтобы принять участие в розыгрыше
      </div>
      <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.form_row}>
          <div
            className={cn(styles.form_field, {
              [styles.form_field_error]: errors.name,
            })}
          >
            <input type="text" placeholder="Ваше имя" {...register('name')} />
            <div className={styles.form_error}>{errors.name?.message}</div>
          </div>
          <div
            className={cn(styles.form_field, {
              [styles.form_field_error]: errors.email,
            })}
          >
            <input type="text" placeholder="E-mail" {...register('email')} />
            <div className={styles.form_error}>{errors.email?.message}</div>
          </div>
          {!isSizeXS && (
            <button className={cn('button', styles.button)} type="submit">
              Далее
            </button>
          )}
        </div>
        <div className={styles.agreement}>
          Нажимая кнопку «Далее», вы соглашаетесь с{' '}
          <a href="#">«Условиями розыгрыша»</a>, а также даёте согласие на
          хранение, обработку и передачу третьим лицам личных данных.
        </div>
        {isSizeXS && (
          <button className={cn('button', styles.button)} type="submit">
            Далее
          </button>
        )}
      </form>
    </AnimateFade>
  );
};
