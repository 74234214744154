import styles from './Bonus.module.scss';

import cn from 'classnames';

type TProps = {
  id: number;
  mod: string;
  title: string;
  bold: string;
  text: string;
  icon: string;
};

export const Bonus = ({ mod, title, bold, text, icon }: TProps) => {
  return (
    <div className={cn(styles.bonus, styles[`bonus--${mod}`])}>
      <div className={styles.bonusIcon}>
        <img src={icon} alt="" />
      </div>
      <div className={styles.bonusTitle}>{title}</div>
      <div className={styles.bonusBold}>{bold}</div>
      <div className={styles.bonusText}>{text}</div>
    </div>
  );
};
