import {
  PLAYER_IMAGE,
  PLAYER_LEFT_IMAGE,
  PLAYER_RIGHT_IMAGE,
  PLAYER_LEFT_SWORD_IMAGE,
  PLAYER_RIGHT_SWORD_IMAGE,
  PLAYER_LEFT_SHIELD_IMAGE,
  PLAYER_RIGHT_SHIELD_IMAGE,
} from '@/src/constants';

export type TProps = {
  ctx: CanvasRenderingContext2D;
  gameWidth: number;
  gameHeight: number;
  baseHeight: number;
  playerBottomIndent: number;
  dir: string;
  display: string;
};

export class Player {
  ctx: CanvasRenderingContext2D;

  width = 88;
  height = 103;

  vy: number;
  vx: number;

  x: number;
  y: number;

  dir: string;
  display: string;

  isSword: boolean;
  isShield: boolean;

  isMovingLeft: boolean;
  isMovingRight: boolean;

  constructor({
    ctx,
    gameWidth,
    gameHeight,
    baseHeight,
    playerBottomIndent,
    dir,
    display,
  }: TProps) {
    this.ctx = ctx;

    this.vy = 0;
    this.vx = 0;

    this.x = gameWidth / 2 - this.width / 2;
    this.y = gameHeight - this.height - playerBottomIndent - baseHeight;

    this.dir = dir;
    this.display = display;
  }

  getImage() {
    if (this.dir === 'left' && this.display === 'sword') {
      return PLAYER_LEFT_SWORD_IMAGE;
    } else if (this.dir === 'right' && this.display === 'sword') {
      return PLAYER_RIGHT_SWORD_IMAGE;
    } else if (this.dir === 'left' && this.display === 'shield') {
      return PLAYER_LEFT_SHIELD_IMAGE;
    } else if (this.dir === 'right' && this.display === 'shield') {
      return PLAYER_RIGHT_SHIELD_IMAGE;
    } else if (this.dir === 'left' && this.display === '') {
      return PLAYER_LEFT_IMAGE;
    } else if (this.dir === 'right' && this.display === '') {
      return PLAYER_RIGHT_IMAGE;
    } else {
      return PLAYER_IMAGE;
    }
  }

  draw() {
    this.ctx.drawImage(
      this.getImage(),
      this.x,
      this.y,
      this.width,
      this.height,
    );
  }

  jump() {
    this.vy = -8;
  }

  jumpHigh() {
    this.vy = -16;
  }
}
