import { useEffect, useRef, useState } from 'react';
import styles from './Game.module.scss';
import { GameEngine } from './components';
import { Header } from '@/src/components/Header';
import { Footer } from '@/src/components/Footer';
import { Menu } from '@/src/components/Menu';
import cn from 'classnames';
import { usePageContext } from '@/src/components/App/PageContext';
import { noop } from '@/src/utils';
import { useDispatch } from 'react-redux';
import {
  setGameLife,
  setGameMoney,
  setGameShield,
  setGameSword,
  setGameTotal,
  setGameUp,
} from '@/src/store/slices/gameSlice';

export const Game = () => {
  const pageContext = usePageContext();
  const changePage = pageContext?.setPage || noop;

  const dispatch = useDispatch();

  const [isTesting] = useState(false); // для тестирования верхних уровней

  const [score, setScore] = useState(0);
  const [lives, setLives] = useState(3);
  const [gameOver, setGameOver] = useState(false);

  useEffect(() => {
    if (gameOver) {
      dispatch(setGameTotal(score));
      changePage('final');
    }
  }, [gameOver]);

  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const gameRef = useRef<GameEngine | null>(null);
  const arrowLeftRef = useRef<HTMLButtonElement | null>(null);
  const arrowRightRef = useRef<HTMLButtonElement | null>(null);
  const buttonCenterRef = useRef<HTMLButtonElement | null>(null);

  const setScoreUp = (n: number) => {
    dispatch(setGameUp(n));
  };

  const setScoreSword = (n: number) => {
    dispatch(setGameSword(n));
  };

  const setScoreShield = (n: number) => {
    dispatch(setGameShield(n));
  };

  const setScoreMoney = (n: number) => {
    dispatch(setGameMoney(n));
  };

  const setScoreLife = (n: number) => {
    dispatch(setGameLife(n));
  };

  useEffect(() => {
    const { current: canvasNode } = canvasRef;

    if (canvasNode !== null) {
      const canvasWidth = canvasNode.offsetWidth;
      const canvasHeight = canvasNode.offsetHeight;

      const ctx = canvasNode.getContext('2d');

      if (ctx === null) {
        return;
      }

      const scale = window.devicePixelRatio;
      canvasNode.width = canvasWidth * scale;
      canvasNode.height = canvasHeight * scale;
      ctx.scale(scale, scale);

      gameRef.current = new GameEngine({
        ctx,
        width: canvasWidth,
        height: canvasHeight,
        arrowLeft: arrowLeftRef.current,
        arrowRight: arrowRightRef.current,
        buttonCenter: buttonCenterRef.current,
        setScore: setScore,
        setScoreUp: setScoreUp,
        setScoreSword: setScoreSword,
        setScoreShield: setScoreShield,
        setScoreMoney: setScoreMoney,
        setScoreLife: setScoreLife,
        lives: lives,
        setLives: setLives,
        setGameOver: setGameOver,
        isTesting: isTesting,
      });

      gameRef.current.init();
    }

    return () => {
      if (canvasRef.current) {
        canvasRef.current = null;
      }
      if (gameRef.current) {
        gameRef.current = null;
      }
    };
  }, []);

  return (
    <div className={styles.game}>
      <div className={styles.top}>
        <Header />
        <div className={cn('container', styles.container)}>
          <div className={styles.field}>
            <Menu lives={lives} score={score} />
            <canvas className="canvas" ref={canvasRef}></canvas>
            <div className={styles.controls}>
              <button
                className={cn(styles.button, styles.buttonLeft)}
                ref={arrowLeftRef}
              ></button>
              <button
                className={styles.centerButton}
                ref={buttonCenterRef}
              ></button>
              <button
                className={cn(styles.button, styles.buttonRight)}
                ref={arrowRightRef}
              ></button>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.bottom}>
        <Footer />
      </div>
    </div>
  );
};
