import styles from './Start.module.scss';
import { Header } from '@/src/components/Header';
import { Footer } from '@/src/components/Footer';
import { Menu } from '@/src/components/Menu';
import cn from 'classnames';
import { usePageContext } from '@/src/components/App/PageContext';
import { noop } from '@/src/utils';

export const Start = () => {
  const pageContext = usePageContext();
  const changePage = pageContext?.setPage || noop;

  return (
    <div className={styles.start}>
      <div className={styles.top}>
        <Header />
        <div className={cn('container', styles.container)}>
          <div className={styles.field}>
            <Menu />
            <div className={styles.rules}>
              <div className={styles.rulesTitle}>Как играть?</div>
              <div className={styles.rulesList}>
                <div className={cn(styles.rulesItem, styles.rulesItem1)}>
                  <p>
                    Прыгайте с&#160;платформы на&#160;платформу, чтобы подняться
                    как&#160;можно&#160;выше
                  </p>
                </div>
                <div className={cn(styles.rulesItem, styles.rulesItem2)}>
                  <p>
                    Перемещайтесь вправо и&#160;влево с&#160;помощью боковых
                    кнопок и&#160;собирайте бонусы
                  </p>
                </div>
                <div className={cn(styles.rulesItem, styles.rulesItem3)}>
                  <p>
                    Воспользуйтесь центральной кнопкой, чтобы уничтожить
                    препятствия
                  </p>
                </div>
                <div className={cn(styles.rulesItem, styles.rulesItem4)}>
                  <p>
                    У&#160;вас есть 120&#160;секунд и&#160;3&#160;попытки, чтобы
                    показать лучшее, на&#160;что вы&#160;способны
                  </p>
                </div>
              </div>
            </div>
            <button
              className={cn('button', styles.button)}
              onClick={() => changePage('game')}
            >
              Начать!
            </button>
          </div>
        </div>
      </div>
      <div className={styles.bottom}>
        <Footer />
      </div>
    </div>
  );
};
