import { BASE_IMAGE } from '@/src/constants';

export type TProps = {
  ctx: CanvasRenderingContext2D;
  gameWidth: number;
  gameHeight: number;
  baseHeight: number;
};

export class Base {
  ctx: CanvasRenderingContext2D;

  width: number;
  height: number;

  x = 0;
  y: number;

  constructor({ ctx, gameWidth, gameHeight, baseHeight }: TProps) {
    this.ctx = ctx;

    this.width = gameWidth;
    this.height = baseHeight;

    this.y = gameHeight - this.height;
  }

  draw() {
    this.ctx.drawImage(BASE_IMAGE, this.x, this.y, this.width, this.height);
  }
}
