import styles from './Registration.module.scss';
import { Header } from '@/src/components/Header';
import { Footer } from '@/src/components/Footer';
import cn from 'classnames';
import { usePageContext } from '@/src/components/App/PageContext';
import { noop } from '@/src/utils';
import { RegistrationHero } from './components/RegistrationHero';
import { RegistrationForm } from './components/RegistrationForm';
import { RegistrationFooter } from './components/RegistrationFooter';

export const Registration = () => {
  const pageContext = usePageContext();
  const _changePage = pageContext?.setPage || noop;

  return (
    <div className={styles.registration}>
      <div className={styles.top}>
        <Header />
      </div>
      <div className={cn('container', styles.container)}>
        <RegistrationHero />

        <RegistrationForm />

        <RegistrationFooter />
      </div>
      <div className={styles.bottom}>
        <Footer />
      </div>
    </div>
  );
};
