import {
  BG1_IMAGE,
  BG2_IMAGE,
  BG3_IMAGE,
  BG4_IMAGE,
  BG5_IMAGE,
  BG6A_IMAGE,
  BG6B_IMAGE,
  BG7A_IMAGE,
  BG7B_IMAGE,
  BG8_IMAGE,
  BG9_IMAGE,
} from '@/src/constants';

export type TProps = {
  ctx: CanvasRenderingContext2D;
  gameWidth: number;
  levelHeight: number;
  bgPosition: number;
  level: number;
  bgLevel: number;
};

export class Bg {
  ctx: CanvasRenderingContext2D;

  width: number;
  height: number;

  x = 0;
  y: number;

  level: number;
  bgLevel: number;

  constructor({
    ctx,
    gameWidth,
    levelHeight,
    bgPosition,
    level,
    bgLevel,
  }: TProps) {
    this.ctx = ctx;

    this.width = gameWidth;
    this.height = levelHeight;

    this.y = bgPosition;

    this.level = level;
    this.bgLevel = bgLevel;
  }

  getBgImage() {
    switch (this.level) {
      case 1:
        return BG1_IMAGE;
      case 2:
        return BG2_IMAGE;
      case 3:
        return BG3_IMAGE;
      case 4:
        return BG4_IMAGE;
      case 5:
        return BG5_IMAGE;
      case 6:
        if (this.bgLevel === 15) {
          return BG6A_IMAGE;
        }
        return BG6B_IMAGE;
      case 7:
        if (this.bgLevel === 19) {
          return BG7A_IMAGE;
        }
        return BG7B_IMAGE;
      case 8:
        return BG8_IMAGE;
      case 9:
        return BG9_IMAGE;
      default:
        return BG1_IMAGE;
    }
  }

  draw() {
    this.ctx.drawImage(
      this.getBgImage(),
      this.x,
      this.y,
      this.width,
      this.height,
    );
  }
}
