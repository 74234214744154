import styles from './Footer.module.scss';

import hhLogo from '../../images/hh.svg';
import { AnimateFade } from '../AnimateFade';

export const Footer = () => {
  return (
    <div className={styles.footer}>
      <div className="container">
        <div className={styles.wrap}>
          <AnimateFade className={styles.left}>
            <p>© ЕВРАЗ 2024</p>
            <div className={styles.line}></div>
            <img src={hhLogo} alt="" />
            <p>Бренд-центр</p>
          </AnimateFade>
          <AnimateFade className={styles.right} delay={0.2}>
            <a href="" target="_blank" rel="noreferrer" className={styles.link}>
              Условия розыгрыша
            </a>
            <a href="" target="_blank" rel="noreferrer" className={styles.link}>
              Участники розыгрыша
            </a>
            <a href="" target="_blank" rel="noreferrer" className={styles.link}>
              Вакансии
            </a>
          </AnimateFade>
        </div>
      </div>
    </div>
  );
};
