import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface IState {
  total: number;
  up: number;
  sword: number;
  shield: number;
  money: number;
  life: number;
}

const initialState: IState = {
  total: 0,
  up: 0,
  sword: 0,
  shield: 0,
  money: 0,
  life: 0,
};

const gameSlice = createSlice({
  name: 'game',
  initialState,
  reducers: {
    setGameTotal(state, action: PayloadAction<number>) {
      state.total = state.total + action.payload;
    },
    setGameUp(state, action: PayloadAction<number>) {
      state.up = state.up + action.payload;
    },
    setGameSword(state, action: PayloadAction<number>) {
      state.sword = state.sword + action.payload;
    },
    setGameShield(state, action: PayloadAction<number>) {
      state.shield = state.shield + action.payload;
    },
    setGameMoney(state, action: PayloadAction<number>) {
      state.money = state.money + action.payload;
    },
    setGameLife(state, action: PayloadAction<number>) {
      state.life = state.life + action.payload;
    },
  },
});

export const {
  setGameTotal,
  setGameUp,
  setGameSword,
  setGameShield,
  setGameMoney,
  setGameLife,
} = gameSlice.actions;

export default gameSlice;
