/* eslint-disable no-console */

import { Dispatch, SetStateAction } from 'react';
import { Bg } from './Bg';
import { Base } from './Base';
import { Platform } from './Platform';
import { Player } from './Player';
import { TBg, TBase, TPlatform, TPlayer } from '@/src/types';
import {
  PLATFORM8A_IMAGE,
  PLATFORM8B_IMAGE,
  PLATFORM8C_IMAGE,
  PLATFORM8D_IMAGE,
  PLATFORM8E_IMAGE,
} from '@/src/constants';

export type TProps = {
  ctx: CanvasRenderingContext2D;
  width: number;
  height: number;
  arrowLeft: HTMLButtonElement | null;
  arrowRight: HTMLButtonElement | null;
  buttonCenter: HTMLButtonElement | null;
  setScore: Dispatch<SetStateAction<number>>;
  setScoreUp: (n: number) => void;
  setScoreSword: (n: number) => void;
  setScoreShield: (n: number) => void;
  setScoreMoney: (n: number) => void;
  setScoreLife: (n: number) => void;
  lives: number;
  setLives: Dispatch<SetStateAction<number>>;
  setGameOver: Dispatch<SetStateAction<boolean>>;
  isTesting: boolean;
};

export class GameEngine {
  ctx: CanvasRenderingContext2D;

  gameWidth: number;
  gameHeight: number;

  arrowLeft: HTMLButtonElement | null;
  arrowRight: HTMLButtonElement | null;
  buttonCenter: HTMLButtonElement | null;

  setScore: Dispatch<SetStateAction<number>>;
  setScoreUp: (n: number) => void;
  setScoreSword: (n: number) => void;
  setScoreShield: (n: number) => void;
  setScoreMoney: (n: number) => void;
  setScoreLife: (n: number) => void;
  setLives: (n: number) => void;
  setGameOver: Dispatch<SetStateAction<boolean>>;

  keyDownHandlerWithContext: (e: KeyboardEvent) => void;
  keyUpHandlerWithContext: (e: KeyboardEvent) => void;

  shieldTimeout: ReturnType<typeof setTimeout>;
  timeout: ReturnType<typeof setTimeout>;

  bgs: TBg[];
  base: TBase;
  platforms: TPlatform[];
  player: TPlayer;

  playerBottomIndent = 18;
  levels = [
    1, 1, 1, 1, 1, 2, 2, 3, 3, 4, 4, 5, 5, 5, 5, 6, 6, 6, 6, 7, 7, 7, 8, 9,
  ];
  levelHeight = 640;
  topLevelHeight = 2966;
  baseHeight = 124;
  platformHeight = 25;
  platformCount = 10;
  topPlatforms = [
    PLATFORM8A_IMAGE,
    PLATFORM8B_IMAGE,
    PLATFORM8C_IMAGE,
    PLATFORM8D_IMAGE,
    PLATFORM8E_IMAGE,
  ];
  bonusWidth = 30;
  bonusHeight = 30;

  bgCount = 2;
  bgLevel = 0;
  bgPosition = 0;
  platformLevel = 0;
  position = 0;
  dir = '';
  display: '';
  gravity = 0.2;
  flag: number;

  isSword: boolean;
  isShield: boolean;

  lives: number;
  score = 0;
  points = 25;
  isGameOver: boolean;

  isTesting: boolean;

  constructor({
    ctx,
    width,
    height,
    arrowLeft,
    arrowRight,
    buttonCenter,
    setScore,
    setScoreUp,
    setScoreSword,
    setScoreShield,
    setScoreMoney,
    setScoreLife,
    lives,
    setLives,
    setGameOver,
    isTesting,
  }: TProps) {
    this.ctx = ctx;

    this.gameWidth = width;
    this.gameHeight = height;

    this.arrowLeft = arrowLeft;
    this.arrowRight = arrowRight;
    this.buttonCenter = buttonCenter;

    this.setScore = setScore;
    this.setScoreUp = setScoreUp;
    this.setScoreSword = setScoreSword;
    this.setScoreShield = setScoreShield;
    this.setScoreMoney = setScoreMoney;
    this.setScoreLife = setScoreLife;
    this.setLives = setLives;
    this.setGameOver = setGameOver;

    this.keyDownHandlerWithContext = this.keyDownHandler.bind(this);
    this.keyUpHandlerWithContext = this.keyUpHandler.bind(this);

    this.bgs = [];
    this.bgPosition = -this.levelHeight + this.gameHeight;
    this.platforms = [];
    this.platformLevel = 0;
    this.dir = 'right';
    this.display = '';
    this.flag = 0;

    this.lives = lives;
    this.isGameOver = false;

    this.isTesting = isTesting;
  }

  init() {
    this.paintCanvas();
    this.paintBg();
    this.paintBase();
    this.paintPlatforms();
    this.paintPlayer();

    window.addEventListener('keydown', this.keyDownHandlerWithContext);
    window.addEventListener('keyup', this.keyUpHandlerWithContext);

    if (this.arrowLeft !== null) {
      if ('ontouchstart' in document.documentElement) {
        this.arrowLeft.addEventListener(
          'touchstart',
          this.handlerLeftButtonDown,
          { passive: true },
        );
        this.arrowLeft.addEventListener(
          'touchcancel',
          this.handlerLeftButtonUp,
        );
        this.arrowLeft.addEventListener('touchend', this.handlerLeftButtonUp);
      } else {
        this.arrowLeft.addEventListener(
          'mousedown',
          this.handlerLeftButtonDown,
        );
        this.arrowLeft.addEventListener('mouseup', this.handlerLeftButtonUp);
      }
    }

    if (this.arrowRight !== null) {
      if ('ontouchstart' in document.documentElement) {
        this.arrowRight.addEventListener(
          'touchstart',
          this.handlerRightButtonDown,
          { passive: true },
        );
        this.arrowRight.addEventListener(
          'touchcancel',
          this.handlerRightButtonUp,
        );
        this.arrowRight.addEventListener('touchend', this.handlerRightButtonUp);
      } else {
        this.arrowRight.addEventListener(
          'mousedown',
          this.handlerRightButtonDown,
        );
        this.arrowRight.addEventListener('mouseup', this.handlerRightButtonUp);
      }
    }

    if (this.buttonCenter !== null) {
      this.buttonCenter.addEventListener('click', this.handlerCenterButton);
    }

    this.gameLoop();
  }

  gameLoop() {
    this.game();
    requestAnimationFrame(this.gameLoop.bind(this));
  }

  game() {
    this.paintCanvas();
    this.bgCalc();
    this.platformCalc();
    this.base.draw();
    this.playerCalc();
    this.player.draw();
  }

  restart() {
    this.bgs = [];
    this.bgLevel = 0;
    this.bgPosition = -this.levelHeight + this.gameHeight;
    this.platforms = [];
    this.platformLevel = 0;
    this.position = 0;
    this.dir = 'right';
    this.display = '';
    this.flag = 0;
    this.isSword = false;
    this.isShield = false;
    this.isGameOver = false;

    this.paintCanvas();
    this.paintBg();
    this.paintBase();
    this.paintPlatforms();
    this.paintPlayer();
    this.bgCalc();
    this.platformCalc();
    this.base.draw();
    this.playerCalc();
    this.player.draw();
  }

  bgCalc() {
    this.bgs.forEach((bg: TBg) => {
      bg.draw();
    });
  }

  platformCalc() {
    this.platforms.forEach((platform: TPlatform) => {
      platform.draw();
    });
  }

  playerCalc() {
    if (this.dir === 'left') {
      this.player.dir = 'left';
    } else if (this.dir === 'right') {
      this.player.dir = 'right';
    }

    // acceleration when the user holds down the keys
    if (this.player.isMovingLeft === true) {
      this.player.x += this.player.vx;
      this.player.vx -= 0.15;
    } else {
      this.player.x += this.player.vx;
      if (this.player.vx < 0) this.player.vx += 0.1;
    }

    if (this.player.isMovingRight === true) {
      this.player.x += this.player.vx;
      this.player.vx += 0.15;
    } else {
      this.player.x += this.player.vx;
      if (this.player.vx > 0) this.player.vx -= 0.1;
    }

    // speed limit
    if (this.player.vx > 8) this.player.vx = 8;
    else if (this.player.vx < -8) this.player.vx = -8;

    // jump when the player hits the base
    if (
      this.player.y + this.player.height - this.playerBottomIndent >
        this.base.y &&
      this.base.y < this.gameHeight
    )
      this.player.jump();

    // make the player move through walls
    if (this.player.x > this.gameWidth) this.player.x = 0 - this.player.width;
    else if (this.player.x < 0 - this.player.width)
      this.player.x = this.gameWidth;

    // movement of player affected by gravity
    if (this.player.y >= this.gameHeight / 2 - this.player.height / 2) {
      this.player.y += this.player.vy;
      this.player.vy += this.gravity;
    }

    // when the player reaches half height, move the platforms to create the illusion of scrolling and create the platforms that are out of viewport
    else {
      this.platforms.forEach((platform, i) => {
        if (this.player.vy < 0) {
          platform.y -= this.player.vy;
        }

        if (platform.y > this.gameHeight) {
          if (i === 9) {
            this.platformLevel++;
          }

          if (this.isTesting) {
            this.platforms[i] = new Platform({
              ctx: this.ctx,
              gameWidth: this.gameWidth,
              position: this.position,
              platformHeight: this.platformHeight,
              type: 'normal',
              visibility: true,
              isBonus: true,
              bonusType: 'up',
              level: this.levels[this.platformLevel],
              topPlatform:
                this.topPlatforms[
                  Math.floor(Math.random() * this.topPlatforms.length)
                ],
            });
            this.platforms[i].x = this.gameWidth / 2 - 50 / 2;
            this.platforms[i].y = platform.y - this.levelHeight;
          } else {
            switch (i) {
              case 0:
                this.platforms[i] = new Platform({
                  ctx: this.ctx,
                  gameWidth: this.gameWidth,
                  position: this.position,
                  platformHeight: this.platformHeight,
                  type: 'normal',
                  visibility: true,
                  isBonus: true,
                  bonusType: 'up',
                  level: this.levels[this.platformLevel],
                  topPlatform:
                    this.topPlatforms[
                      Math.floor(Math.random() * this.topPlatforms.length)
                    ],
                });
                this.platforms[i].y = platform.y - this.levelHeight;
                break;
              case 1:
                this.platforms[i] = new Platform({
                  ctx: this.ctx,
                  gameWidth: this.gameWidth,
                  position: this.position,
                  platformHeight: this.platformHeight,
                  type: 'normal',
                  visibility: true,
                  isBonus: true,
                  bonusType: 'shield',
                  level: this.levels[this.platformLevel],
                  topPlatform:
                    this.topPlatforms[
                      Math.floor(Math.random() * this.topPlatforms.length)
                    ],
                });
                this.platforms[i].y = platform.y - this.levelHeight;
                break;
              case 2:
                this.platforms[i] = new Platform({
                  ctx: this.ctx,
                  gameWidth: this.gameWidth,
                  position: this.position,
                  platformHeight: this.platformHeight,
                  type: 'normal',
                  visibility: true,
                  isObstacle: true,
                  level: this.levels[this.platformLevel],
                  topPlatform:
                    this.topPlatforms[
                      Math.floor(Math.random() * this.topPlatforms.length)
                    ],
                });
                this.platforms[i].y = platform.y - this.levelHeight;
                break;
              case 4:
                this.platforms[i] = new Platform({
                  ctx: this.ctx,
                  gameWidth: this.gameWidth,
                  position: this.position,
                  platformHeight: this.platformHeight,
                  type: 'normal',
                  visibility: true,
                  isBonus: true,
                  bonusType: 'life',
                  level: this.levels[this.platformLevel],
                  topPlatform:
                    this.topPlatforms[
                      Math.floor(Math.random() * this.topPlatforms.length)
                    ],
                });
                this.platforms[i].y = platform.y - this.levelHeight;
                break;
              case 5:
                this.platforms[i] = new Platform({
                  ctx: this.ctx,
                  gameWidth: this.gameWidth,
                  position: this.position,
                  platformHeight: this.platformHeight,
                  type: 'normal',
                  visibility: true,
                  isBonus: true,
                  bonusType: 'money',
                  level: this.levels[this.platformLevel],
                  topPlatform:
                    this.topPlatforms[
                      Math.floor(Math.random() * this.topPlatforms.length)
                    ],
                });
                this.platforms[i].y = platform.y - this.levelHeight;
                break;
              case 6:
                this.platforms[i] = new Platform({
                  ctx: this.ctx,
                  gameWidth: this.gameWidth,
                  position: this.position,
                  platformHeight: this.platformHeight,
                  type: 'broken',
                  visibility: true,
                  level: this.levels[this.platformLevel],
                  topPlatform:
                    this.topPlatforms[
                      Math.floor(Math.random() * this.topPlatforms.length)
                    ],
                });
                this.platforms[i].y = platform.y - this.levelHeight;
                break;
              case 7:
                this.platforms[i] = new Platform({
                  ctx: this.ctx,
                  gameWidth: this.gameWidth,
                  position: this.position,
                  platformHeight: this.platformHeight,
                  type: 'normal',
                  visibility: true,
                  isBonus: true,
                  bonusType: 'sword',
                  level: this.levels[this.platformLevel],
                  topPlatform:
                    this.topPlatforms[
                      Math.floor(Math.random() * this.topPlatforms.length)
                    ],
                });
                this.platforms[i].y = platform.y - this.levelHeight;
                break;
              default:
                this.platforms[i] = new Platform({
                  ctx: this.ctx,
                  gameWidth: this.gameWidth,
                  position: this.position,
                  platformHeight: this.platformHeight,
                  type: 'normal',
                  visibility: true,
                  level: this.levels[this.platformLevel],
                  topPlatform:
                    this.topPlatforms[
                      Math.floor(Math.random() * this.topPlatforms.length)
                    ],
                });
                this.platforms[i].y = platform.y - this.levelHeight;
                break;
            }
          }
        }
      });

      this.bgs.forEach((bg, i) => {
        if (this.player.vy < 0) {
          bg.y -= this.player.vy;
        }

        if (bg.y > this.gameHeight) {
          this.bgs[i] = new Bg({
            ctx: this.ctx,
            gameWidth: this.gameWidth,
            levelHeight:
              this.levels[this.bgLevel] === 8 || this.levels[this.bgLevel] === 9
                ? this.topLevelHeight
                : this.levelHeight,
            bgPosition:
              this.levels[this.bgLevel] === 8
                ? bg.y - this.topLevelHeight - this.levelHeight
                : this.levels[this.bgLevel] === 9
                ? bg.y - this.topLevelHeight - this.topLevelHeight
                : bg.y - this.levelHeight - this.levelHeight,
            level: this.levels[this.bgLevel],
            bgLevel: this.bgLevel,
          });
          this.bgLevel++;
        }
      });

      if (this.player.vy < 0) {
        this.base.y -= this.player.vy;
      }

      this.player.vy += this.gravity;

      if (this.player.vy >= 0) {
        this.player.y += this.player.vy;
        this.player.vy += this.gravity;
      }

      this.score++;
      this.setScore(this.score);
    }

    this.collides();

    // gameover if the player hits the bottom
    if (
      this.base.y > this.gameHeight &&
      this.player.y + this.player.height > this.gameHeight
    )
      this.isGameOver = true;

    if (this.isGameOver) {
      this.gameOver();
    }
  }

  collides() {
    this.platforms.forEach((platform) => {
      if (
        this.player.vy > 0 &&
        this.player.x + this.platformHeight < platform.x + platform.width &&
        this.player.x + this.player.width - this.platformHeight > platform.x &&
        this.player.y + this.player.height - this.playerBottomIndent >
          platform.y &&
        this.player.y + this.player.height - this.playerBottomIndent <
          platform.y + platform.height
      ) {
        if (platform.type === 'broken' && !this.isShield) {
          platform.visibility = false;
        } else if (platform.isObstacle && !this.isShield) {
          platform.visibility = false;
          platform.isObstacle = false;
        } else if (platform.isObstacle && this.isShield) {
          platform.isObstacle = false;
        } else if (platform.isBonus && platform.bonusType === 'up') {
          platform.isBonus = false;
          this.player.jumpHigh();
          this.setScoreUp(this.points);
          this.setScore((prev) => prev + this.points);
        } else if (platform.isBonus && platform.bonusType === 'sword') {
          platform.isBonus = false;
          this.isSword = true;
          this.isShield = false;
          this.player.display = 'sword';
          this.setScoreSword(this.points);
          this.setScore((prev) => prev + this.points);
          clearTimeout(this.shieldTimeout);
        } else if (platform.isBonus && platform.bonusType === 'shield') {
          platform.isBonus = false;
          this.isShield = true;
          this.isSword = false;
          this.player.display = 'shield';
          this.setScoreShield(this.points);
          this.setScore((prev) => prev + this.points);
          clearTimeout(this.shieldTimeout);
          this.shieldTimeout = setTimeout(() => {
            this.isShield = false;
            this.player.display = '';
          }, 10000);
        } else if (platform.isBonus && platform.bonusType === 'money') {
          platform.isBonus = false;
          this.setScoreMoney(this.points);
          this.setScore((prev) => prev + this.points);
        } else if (platform.isBonus && platform.bonusType === 'life') {
          platform.isBonus = false;
          this.setScoreLife(this.points);
          this.setScore((prev) => prev + this.points);
          if (this.lives < 3) {
            this.lives++;
            this.setLives(this.lives);
          }
        } else if (platform.visibility) {
          this.player.jump();
        }
      }
    });
  }

  gameOver() {
    this.platforms.forEach((platform) => {
      platform.y -= 12;
    });

    if (this.player.y > this.gameHeight / 2 && this.flag === 0) {
      this.player.y -= 8;
      this.player.vy = 0;
    } else if (this.player.y < this.gameHeight / 2) this.flag = 1;
    else if (this.player.y + this.player.height > this.gameHeight) {
      this.lives--;
      this.setLives(this.lives);
      if (this.lives > 0) {
        this.restart();
      } else {
        this.setGameOver(true);
      }
    }
  }

  paintCanvas() {
    this.ctx.clearRect(0, 0, this.gameWidth, this.gameHeight);
  }

  paintBg() {
    for (let i = 0; i < this.bgCount; i++) {
      this.bgs.push(
        new Bg({
          ctx: this.ctx,
          gameWidth: this.gameWidth,
          levelHeight:
            this.levels[this.bgLevel] === 8 || this.levels[this.bgLevel] === 9
              ? this.topLevelHeight
              : this.levelHeight,
          bgPosition: this.bgPosition,
          level: this.levels[this.bgLevel],
          bgLevel: this.bgLevel,
        }),
      );
      this.bgPosition -=
        this.levels[this.bgLevel] === 8 || this.levels[this.bgLevel] === 9
          ? this.topLevelHeight
          : this.levelHeight;
      this.bgLevel++;
    }
  }

  paintBase() {
    this.base = new Base({
      ctx: this.ctx,
      gameWidth: this.gameWidth,
      gameHeight: this.gameHeight,
      baseHeight: this.baseHeight,
    });
  }

  paintPlatforms() {
    if (this.isTesting) {
      for (let i = 0; i < this.platformCount; i++) {
        this.platforms.push(
          new Platform({
            ctx: this.ctx,
            gameWidth: this.gameWidth,
            position: this.position,
            platformHeight: this.platformHeight,
            type: 'normal',
            visibility: true,
            isBonus: true,
            bonusType: 'up',
            level: this.levels[this.platformLevel],
            topPlatform:
              this.topPlatforms[
                Math.floor(Math.random() * this.topPlatforms.length)
              ],
          }),
        );
        this.platforms[i].x = this.gameWidth / 2 - 50 / 2;
        this.position += this.levelHeight / this.platformCount;
      }
    } else {
      for (let i = 0; i < this.platformCount; i++) {
        switch (i) {
          case 0:
            this.platforms.push(
              new Platform({
                ctx: this.ctx,
                gameWidth: this.gameWidth,
                position: this.position,
                platformHeight: this.platformHeight,
                type: 'normal',
                visibility: true,
                isBonus: true,
                bonusType: 'up',
                level: this.levels[this.platformLevel],
                topPlatform:
                  this.topPlatforms[
                    Math.floor(Math.random() * this.topPlatforms.length)
                  ],
              }),
            );
            this.position += this.levelHeight / this.platformCount;
            break;
          case 1:
            this.platforms.push(
              new Platform({
                ctx: this.ctx,
                gameWidth: this.gameWidth,
                position: this.position,
                platformHeight: this.platformHeight,
                type: 'normal',
                visibility: true,
                isBonus: true,
                bonusType: 'shield',
                level: this.levels[this.platformLevel],
                topPlatform:
                  this.topPlatforms[
                    Math.floor(Math.random() * this.topPlatforms.length)
                  ],
              }),
            );
            this.position += this.levelHeight / this.platformCount;
            break;
          case 2:
            this.platforms.push(
              new Platform({
                ctx: this.ctx,
                gameWidth: this.gameWidth,
                position: this.position,
                platformHeight: this.platformHeight,
                type: 'normal',
                visibility: true,
                isObstacle: true,
                level: this.levels[this.platformLevel],
                topPlatform:
                  this.topPlatforms[
                    Math.floor(Math.random() * this.topPlatforms.length)
                  ],
              }),
            );
            this.position += this.levelHeight / this.platformCount;
            break;
          case 4:
            this.platforms.push(
              new Platform({
                ctx: this.ctx,
                gameWidth: this.gameWidth,
                position: this.position,
                platformHeight: this.platformHeight,
                type: 'normal',
                visibility: true,
                isBonus: true,
                bonusType: 'life',
                level: this.levels[this.platformLevel],
                topPlatform:
                  this.topPlatforms[
                    Math.floor(Math.random() * this.topPlatforms.length)
                  ],
              }),
            );
            this.position += this.levelHeight / this.platformCount;
            break;
          case 5:
            this.platforms.push(
              new Platform({
                ctx: this.ctx,
                gameWidth: this.gameWidth,
                position: this.position,
                platformHeight: this.platformHeight,
                type: 'normal',
                visibility: true,
                isBonus: true,
                bonusType: 'money',
                level: this.levels[this.platformLevel],
                topPlatform:
                  this.topPlatforms[
                    Math.floor(Math.random() * this.topPlatforms.length)
                  ],
              }),
            );
            this.position += this.levelHeight / this.platformCount;
            break;
          case 6:
            this.platforms.push(
              new Platform({
                ctx: this.ctx,
                gameWidth: this.gameWidth,
                position: this.position,
                platformHeight: this.platformHeight,
                type: 'broken',
                visibility: true,
                level: this.levels[this.platformLevel],
                topPlatform:
                  this.topPlatforms[
                    Math.floor(Math.random() * this.topPlatforms.length)
                  ],
              }),
            );
            this.position += this.levelHeight / this.platformCount;
            break;
          case 7:
            this.platforms.push(
              new Platform({
                ctx: this.ctx,
                gameWidth: this.gameWidth,
                position: this.position,
                platformHeight: this.platformHeight,
                type: 'normal',
                visibility: true,
                isBonus: true,
                bonusType: 'sword',
                level: this.levels[this.platformLevel],
                topPlatform:
                  this.topPlatforms[
                    Math.floor(Math.random() * this.topPlatforms.length)
                  ],
              }),
            );
            this.position += this.levelHeight / this.platformCount;
            break;
          default:
            this.platforms.push(
              new Platform({
                ctx: this.ctx,
                gameWidth: this.gameWidth,
                position: this.position,
                platformHeight: this.platformHeight,
                type: 'normal',
                visibility: true,
                level: this.levels[this.platformLevel],
                topPlatform:
                  this.topPlatforms[
                    Math.floor(Math.random() * this.topPlatforms.length)
                  ],
              }),
            );
            this.position += this.levelHeight / this.platformCount;
            break;
        }
      }
    }
  }

  paintPlayer() {
    this.player = new Player({
      ctx: this.ctx,
      gameWidth: this.gameWidth,
      gameHeight: this.gameHeight,
      baseHeight: this.baseHeight,
      playerBottomIndent: this.playerBottomIndent,
      dir: this.dir,
      display: this.display,
    });
  }

  handlerLeftButtonDown = () => {
    this.dir = 'left';
    this.player.isMovingLeft = true;

    clearTimeout(this.timeout);

    this.timeout = setTimeout(() => {
      this.dir = 'left';
      this.player.isMovingLeft = false;
    }, 1000);
  };

  handlerRightButtonDown = () => {
    this.dir = 'right';
    this.player.isMovingRight = true;

    clearTimeout(this.timeout);

    this.timeout = setTimeout(() => {
      this.dir = 'right';
      this.player.isMovingRight = false;
    }, 1000);
  };

  handlerLeftButtonUp = () => {
    this.dir = 'left';
    this.player.isMovingLeft = false;
  };

  handlerRightButtonUp = () => {
    this.dir = 'right';
    this.player.isMovingRight = false;
  };

  handlerCenterButton = () => {
    if (this.isSword) {
      this.platforms.forEach((platform, i) => {
        if (i === 2) {
          platform.isObstacle = false;
        }
      });
      this.isSword = false;
      this.player.display = '';
    }
  };

  keyDownHandler({ code }: KeyboardEvent) {
    switch (code) {
      case 'ArrowLeft':
      case 'KeyA':
        this.dir = 'left';
        this.player.isMovingLeft = true;
        break;
      case 'ArrowRight':
      case 'KeyD':
        this.dir = 'right';
        this.player.isMovingRight = true;
        break;
      default:
        break;
    }
  }

  keyUpHandler({ code }: KeyboardEvent) {
    switch (code) {
      case 'ArrowLeft':
      case 'KeyA':
        this.dir = 'left';
        this.player.isMovingLeft = false;
        break;
      case 'ArrowRight':
      case 'KeyD':
        this.dir = 'right';
        this.player.isMovingRight = false;
        break;
      default:
        break;
    }
  }
}
