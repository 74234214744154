import styles from './Final.module.scss';
import { Header } from '@/src/components/Header';
import { Footer } from '@/src/components/Footer';
import cn from 'classnames';
import { useAppSelector } from '@/src/hooks';
import {
  gameLifeSelector,
  gameMoneySelector,
  gameShieldSelector,
  gameSwordSelector,
  gameTotalSelector,
  gameUpSelector,
} from '@/src/store/selectors/game';
import icon1Src from './images/icon1.svg';
import icon2Src from './images/icon2.svg';
import icon3Src from './images/icon3.svg';
import icon4Src from './images/icon4.svg';
import icon5Src from './images/icon5.svg';
import { Swiper, SwiperSlide } from 'swiper/react';
import { FreeMode } from 'swiper/modules';

export const Final = () => {
  const scoreTotal = useAppSelector(gameTotalSelector);
  const scoreUp = useAppSelector(gameUpSelector);
  const scoreSword = useAppSelector(gameSwordSelector);
  const scoreShield = useAppSelector(gameShieldSelector);
  const scoreMoney = useAppSelector(gameMoneySelector);
  const scoreLife = useAppSelector(gameLifeSelector);

  return (
    <div className={styles.final}>
      <div className={styles.top}>
        <Header />
        <div className={cn('container', styles.container)}>
          <div className={styles.total}>
            <span>340{scoreTotal} очков</span> Вот это прорыв!
          </div>
          <div className={styles.results}>
            <Swiper
              freeMode={true}
              slidesPerView={'auto'}
              loop={false}
              modules={[FreeMode]}
              slidesOffsetBefore={20}
              slidesOffsetAfter={20}
              breakpoints={{
                700: {
                  slidesOffsetBefore: 0,
                  slidesOffsetAfter: 0,
                },
              }}
            >
              <SwiperSlide>
                <div className={styles.results_row}>
                  <div className={styles.item}>
                    <img src={icon1Src} alt="" />
                    <b>+{scoreSword}</b>
                    Закалился <br /> характер
                  </div>

                  <div className={styles.item}>
                    <img src={icon2Src} alt="" />
                    <b>+{scoreUp}</b>
                    Взлетела <br /> карьера
                  </div>

                  <div className={styles.item}>
                    <img src={icon3Src} alt="" />
                    <b>+{scoreShield}</b>
                    Усилилась <br /> безопасность
                  </div>

                  <div className={styles.item}>
                    <img src={icon4Src} alt="" />
                    <b>+{scoreLife}</b>
                    Окрепло <br /> здоровье
                  </div>

                  <div className={styles.item}>
                    <img src={icon5Src} alt="" />
                    <b>+{scoreMoney}</b>
                    Вырос <br /> доход
                  </div>
                </div>
              </SwiperSlide>
            </Swiper>
          </div>

          <div className={styles.vote}>
            <a href="#" className={cn('button', styles.vote_button)}>
              Голосовать
            </a>
            <p className={styles.vote_text}>
              Поддержите ЕВРАЗ в Рейтинге работодателей России
            </p>
          </div>
        </div>
      </div>
      <div className={styles.bottom}>
        <Footer />
      </div>
    </div>
  );
};
