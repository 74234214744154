import { Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';

import { Bonus } from '../Bonus';
import personalityBonusIconSrc from '@/src/images/personality.png';
import careerBonusIconSrc from '@/src/images/career.png';
import safetyBonusIconSrc from '@/src/images/safety.png';
import healthBonusIconSrc from '@/src/images/health.png';
import incomeBonusIconSrc from '@/src/images/income.png';

const BONUSES_DATA = [
  {
    id: 0,
    icon: personalityBonusIconSrc,
    mod: '',
    title: 'Характер',
    bold: 'Помогает уничтожать любые препятствия на пути к успеху',
    text: 'Мы в ЕВРАЗе ответственно подходим к любому делу, не боимся сложностей, умеем добиваться целей и гордимся своей работой',
  },
  {
    id: 1,
    icon: careerBonusIconSrc,
    mod: '',
    title: 'Карьера',
    bold: 'Ускоряет движение вверх',
    text: 'ЕВРАЗ нас вдохновляет, дает возможности и инструменты для роста, помогает выстроить персональный трек развития',
  },
  {
    id: 2,
    icon: safetyBonusIconSrc,
    mod: '',
    title: 'Безопасность',
    bold: 'Защищает от столкновений с препятствиями',
    text: 'ЕВРАЗ обеспечивает нас всеми средствами защиты и создает достойные условия для безопасной и комфортной работы',
  },
  {
    id: 3,
    icon: healthBonusIconSrc,
    mod: '',
    title: 'Здоровье',
    bold: 'Дает дополнительные жизни',
    text: 'Забота о здоровье сотрудников не ограничивается ДМС: у нас есть врачи в цехах, программа профилактики профзаболеваний и санаторно-курортное лечение',
  },
  {
    id: 4,
    icon: incomeBonusIconSrc,
    mod: '',
    title: 'Доход',
    bold: 'Увеличивает общий счет',
    text: 'Благодаря достижениям в работе мы можем влиять на свой доход: чем сложнее работа и лучше результаты, тем выше зарплата',
  },
];

export const BonusSlider = () => {
  return (
    <Swiper
      spaceBetween={8}
      slidesPerView={'auto'}
      modules={[Pagination]}
      pagination={{ clickable: true }}
    >
      {BONUSES_DATA.map((item) => (
        <SwiperSlide key={item.id}>
          <Bonus {...item} />
        </SwiperSlide>
      ))}
    </Swiper>
  );
};
