import styles from './Home.module.scss';
import { Header } from '@/src/components/Header';
import { Footer } from '@/src/components/Footer';
import cn from 'classnames';
import { usePageContext } from '@/src/components/App/PageContext';
import { noop } from '@/src/utils';
import { BonusSlider } from './components/BonusSlider';
import { motion } from 'framer-motion';

export const Home = () => {
  const pageContext = usePageContext();
  const changePage = pageContext?.setPage || noop;

  const handleGoGame = () => {
    changePage('registration');
  };

  return (
    <div className={styles.home}>
      <div className={styles.top}>
        <Header isLarge={true} />
      </div>
      <div className={styles.main}>
        <div className={cn('container', styles.container)}>
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            className={styles.hero}
            viewport={{
              once: true,
            }}
          >
            <motion.div
              initial={{ opacity: 0, x: -100 }}
              whileInView={{ opacity: 1, x: 0 }}
              transition={{ delay: 0.5 }}
              className={styles.heroTitle}
              viewport={{
                once: true,
              }}
            >
              То, что других восхищает, для нас — просто работа!
            </motion.div>
            <div className={styles.heroText}>
              <motion.p
                initial={{ opacity: 0, x: -100 }}
                whileInView={{ opacity: 1, x: 0 }}
                transition={{ delay: 0.7 }}
                viewport={{
                  once: true,
                }}
              >
                Попробуйте добраться от нижнего горизонта карьера до пика
                небоскреба: играйте с нами и покажите, какую высоту сможете
                покорить!
              </motion.p>
              <motion.p
                initial={{ opacity: 0, x: -100 }}
                whileInView={{ opacity: 1, x: 0 }}
                transition={{ delay: 0.9 }}
                viewport={{
                  once: true,
                }}
              >
                <b>Призы внутри!</b>
              </motion.p>
            </div>
            <motion.button
              initial={{ opacity: 0, x: -100 }}
              whileInView={{ opacity: 1, x: 0 }}
              transition={{ delay: 1.1 }}
              className={cn('button', styles.heroButton, styles.button)}
              type="button"
              onClick={handleGoGame}
              viewport={{
                once: true,
              }}
            >
              Играть
            </motion.button>
          </motion.div>

          <motion.div
            initial={{ opacity: 0, y: 30 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ delay: 1, duration: 0.5 }}
            className={styles.voting}
            viewport={{
              once: true,
            }}
          >
            <div className={styles.votingInner}>
              <div className={styles.votingText}>
                Создать рельсы нового поколения для железных дорог, сделать
                сверхпрочную балку для огромных стадионов, мостов, небоскребов и
                космодромов – нет такой задачи и такой высоты, которую не смогли
                бы покорить металлурги ЕВРАЗа!
              </div>
              <div className={styles.votingTitle}>
                Восхищают достижения металлургов?
              </div>
              <div className={styles.votingLarge}>
                Поддержите ЕВРАЗ <br />
                в&#160;рейтинге Работодателей России!
              </div>
              <a
                className={cn('button', styles.votingButton)}
                href="#"
                target="_blank"
              >
                Голосовать
              </a>
            </div>
          </motion.div>

          <div className={styles.bonuses}>
            <motion.div
              initial={{ opacity: 0, x: -100 }}
              whileInView={{ opacity: 1, x: 0 }}
              className={styles.bonusesTitle}
              viewport={{
                margin: '0px 0px -100px 0px',
                once: true,
              }}
            >
              Собирайте бонусы, которые помогут победить в игре:
            </motion.div>
            <motion.div
              initial={{ opacity: 0, x: 100 }}
              whileInView={{ opacity: 1, x: 0 }}
              viewport={{
                margin: '0px 0px -100px 0px',
                once: true,
              }}
            >
              <BonusSlider />
            </motion.div>
          </div>

          <div className={styles.slogan}>
            <motion.div
              initial={{ opacity: 0, y: 50 }}
              whileInView={{ opacity: 1, y: 0 }}
              className={styles.sloganTitle}
              viewport={{
                once: true,
              }}
            >
              Верьте в себя!
            </motion.div>
            <motion.button
              initial={{ opacity: 0, y: 50 }}
              whileInView={{ opacity: 1, y: 0 }}
              className={cn('button', styles.sloganButton)}
              type="button"
              onClick={handleGoGame}
              viewport={{
                once: true,
              }}
              transition={{ delay: 0.2 }}
            >
              Играть
            </motion.button>
          </div>
        </div>
      </div>

      <div className={styles.bottom}>
        <Footer />
      </div>
    </div>
  );
};
