import {
  PLATFORM1_IMAGE,
  PLATFORM1_BROKEN_IMAGE,
  PLATFORM2_IMAGE,
  PLATFORM2_BROKEN_IMAGE,
  PLATFORM3_IMAGE,
  PLATFORM3_BROKEN_IMAGE,
  PLATFORM4_IMAGE,
  PLATFORM4_BROKEN_IMAGE,
  PLATFORM5_IMAGE,
  PLATFORM5_BROKEN_IMAGE,
  PLATFORM6_IMAGE,
  PLATFORM6_BROKEN_IMAGE,
  PLATFORM7_IMAGE,
  PLATFORM7_BROKEN_IMAGE,
  PLATFORM8_BROKEN_IMAGE,
  OBSTACLE1_IMAGE,
  OBSTACLE2_IMAGE,
  OBSTACLE3_IMAGE,
  OBSTACLE4_IMAGE,
  OBSTACLE5_IMAGE,
  OBSTACLE6_IMAGE,
  OBSTACLE7_IMAGE,
  UP_IMAGE,
  SWORD_IMAGE,
  SHIELD_IMAGE,
  MONEY_IMAGE,
  LIFE_IMAGE,
} from '@/src/constants';

export type TProps = {
  ctx: CanvasRenderingContext2D;
  gameWidth: number;
  platformHeight: number;
  position: number;
  type: string;
  visibility: boolean;
  isObstacle?: boolean;
  isBonus?: boolean;
  bonusType?: string;
  level: number;
  topPlatform: HTMLImageElement;
};

export class Platform {
  ctx: CanvasRenderingContext2D;

  width = 50;
  height: number;

  x: number;
  y: number;

  type: string;
  visibility: boolean;

  isObstacle: boolean;

  obstacleWidth = 48;
  obstacleHeight = 48;

  isBonus: boolean;
  bonusType: string;

  bonusWidth = 30;
  bonusHeight = 30;

  level: number;
  topPlatform: HTMLImageElement;

  constructor({
    ctx,
    gameWidth,
    platformHeight,
    position,
    type,
    visibility,
    isObstacle = false,
    isBonus = false,
    bonusType = '',
    level,
    topPlatform,
  }: TProps) {
    this.ctx = ctx;

    this.height = platformHeight;

    this.x = Math.random() * (gameWidth - this.width);
    this.y = position;

    this.type = type;
    this.visibility = visibility;

    this.isObstacle = isObstacle;

    this.isBonus = isBonus;
    this.bonusType = bonusType;

    this.level = level;
    this.topPlatform = topPlatform;
  }

  getImage() {
    if (this.type === 'broken') {
      switch (this.level) {
        case 1:
          return PLATFORM1_BROKEN_IMAGE;
        case 2:
          return PLATFORM2_BROKEN_IMAGE;
        case 3:
          return PLATFORM3_BROKEN_IMAGE;
        case 4:
          return PLATFORM4_BROKEN_IMAGE;
        case 5:
          return PLATFORM5_BROKEN_IMAGE;
        case 6:
          return PLATFORM6_BROKEN_IMAGE;
        case 7:
          return PLATFORM7_BROKEN_IMAGE;
        case 8:
        case 9:
          return PLATFORM8_BROKEN_IMAGE;
        default:
          return PLATFORM8_BROKEN_IMAGE;
      }
    } else {
      switch (this.level) {
        case 1:
          return PLATFORM1_IMAGE;
        case 2:
          return PLATFORM2_IMAGE;
        case 3:
          return PLATFORM3_IMAGE;
        case 4:
          return PLATFORM4_IMAGE;
        case 5:
          return PLATFORM5_IMAGE;
        case 6:
          return PLATFORM6_IMAGE;
        case 7:
          return PLATFORM7_IMAGE;
        case 8:
        case 9:
          return this.topPlatform;
        default:
          return this.topPlatform;
      }
    }
  }

  getObstacle() {
    switch (this.level) {
      case 1:
        return OBSTACLE1_IMAGE;
      case 2:
        return OBSTACLE2_IMAGE;
      case 3:
      case 4:
        return OBSTACLE3_IMAGE;
      case 5:
        return OBSTACLE4_IMAGE;
      case 6:
        return OBSTACLE5_IMAGE;
      case 7:
        return OBSTACLE6_IMAGE;
      case 8:
      case 9:
        return OBSTACLE7_IMAGE;
      default:
        return OBSTACLE7_IMAGE;
    }
  }

  getBonusImage() {
    switch (this.bonusType) {
      case 'up':
        return UP_IMAGE;
      case 'sword':
        return SWORD_IMAGE;
      case 'shield':
        return SHIELD_IMAGE;
      case 'money':
        return MONEY_IMAGE;
      case 'life':
        return LIFE_IMAGE;
      default:
        return MONEY_IMAGE;
    }
  }

  draw() {
    if (this.visibility) {
      this.ctx.drawImage(
        this.getImage(),
        this.x,
        this.y,
        this.width,
        this.height,
      );
    }
    if (this.isObstacle) {
      this.ctx.drawImage(
        this.getObstacle(),
        this.x + this.width / 2 - this.obstacleWidth / 2,
        this.y - this.obstacleHeight,
        this.obstacleWidth,
        this.obstacleHeight,
      );
    }
    if (this.isBonus) {
      this.ctx.drawImage(
        this.getBonusImage(),
        this.x + this.width / 2 - this.bonusWidth / 2,
        this.y - this.bonusHeight,
        this.bonusWidth,
        this.bonusHeight,
      );
    }
  }
}
